<template>
  <div>
    <div id="collectDetail">
      <div class="headBox">
        <div class="imgIcon" @click="cardShow = true">
          <div class="imgIconBox">
            <img class="cardImg" :src="productDetail.image_icon" alt="" srcset="" />
            <img class="levelIcon" :src="levelsChange(productDetail.levels)" />
          </div>
        </div>
        <div class="collectionBox">
          <div class="model"></div>
          <div class="collectionBg">
            <div class="name">{{ productDetail.items_name }}</div>

            <div class="iconNum">
              <img src="../imgs/comm/collectionIcon.png" alt="" srcset="" />

              <div class="num">
                <div>编号</div>
                <div>{{ productDetail.token_id + '-' + productDetail.serial_num }}</div>
              </div>
            </div>

            <div class="introduce">
              <div class="pic">
                <img src="../imgs/comm/collection_js.png" />
                <!-- <van-icon
                class="questionIcon"
                name="question-o"
                size="0.45rem"
                @click="popShow = true"
              /> -->
              </div>
              <div class="desc">{{ productDetail.description }}</div>
            </div>
            <img class="headBg" src="../imgs/comm/collectionBg.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div class="paddingBox">
        <div class="bgBox">
          <div class="contentBox">
            <div class="countItem">
              <div class="name">持有人</div>
              <div class="num">{{ productDetail.user_name }}</div>
            </div>
            <div class="countItem">
              <div class="name">收藏时间</div>
              <div class="num">{{ timeChange(productDetail.created_time) }}</div>
            </div>
          </div>
        </div>
        <div class="bgBox paramsBox">
          <div class="contentBox">
            <div class="titlePic">
              <img src="../imgs/comm/collcetion_parm.png" alt="" srcset="" />
            </div>
            <div class="countItem">
              <div class="name">认证标识</div>
              <div class="num">已上链</div>
            </div>
            <div class="countItem">
              <div class="name">认证标准</div>
              <div class="num">ERC1155</div>
            </div>
            <div class="countItem">
              <div class="name">认证网络</div>
              <div class="num">{{ productDetail.token_network }}</div>
            </div>
            <div class="countItem">
              <div class="name">发行方</div>
              <div class="num">{{ productDetail.publisher }}</div>
            </div>
            <div class="countItem">
              <div class="name">铸造总量</div>
              <div class="num">{{ productDetail.token_num }}</div>
            </div>
            <div class="countItem">
              <div class="name">当前流通</div>
              <div class="num">{{ productDetail.active_num }}</div>
            </div>
            <div class="countItem">
              <div class="name">累计发售</div>
              <div class="num">{{ productDetail.sell_num }}</div>
            </div>

            <div class="countItem">
              <div class="name">累计销毁</div>
              <div class="num">{{ productDetail.destory_num }}</div>
            </div>
            <div class="countItem">
              <div class="name">累计赠送</div>
              <div class="num">{{ productDetail.gift_num }}</div>
            </div>
            <div class="countItem">
              <div class="name">累计合成</div>
              <div class="num">{{ productDetail.merge_num }}</div>
            </div>

            <div class="countItem">
              <div class="name">系统余量</div>
              <div class="num">{{ productDetail.remain_num }}</div>
            </div>
            <div class="countItem">
              <div class="name">更新时间</div>
              <div class="num">{{ timeChange(productDetail.stat_time, 'YYYY-MM-DD HH:mm') }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 展示图片弹窗 -->
      <van-popup v-model:show="cardShow">
        <div class="cardPop">
          <div class="name">{{ productDetail.items_name }}</div>
          <img
            style="pointer-events: auto !important"
            :src="productDetail.image_icon"
            alt=""
            srcset=""
          />
          <div v-if="productDetail.image_icon" class="saveTip">长按图片保存</div>
        </div>
      </van-popup>

      <!-- 说明弹窗 -->
      <van-popup class="vantPop" v-model:show="popShow">
        <div class="failPopup">
          <div class="head">说明</div>
          <div class="content">
            <div>铸造总量：上链铸造的总数量，不会增发；</div>
            <div>当前流通：当前累计市面流通该藏品的总数量；</div>
            <div>累计发售：当前累计发售的总数量；</div>
            <div>累计销毁：当前累计用于分解和合成的总数量；</div>
            <div>累计赠送：当前累计通过活动赠送、积分抽奖、兑换的总数量；</div>
            <div>累计合成：当前累计通过合成方式发行的总数量；</div>

            <div>系统余量：藏品总铸造数量减去当前发售、销毁、赠送、合成后剩余的总数量。</div>
          </div>
          <div class="btn">
            <div @click="popShow = false">我已知晓</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { getCollectionsInfo } from '@/service/home';
import { nftUtils } from '@/utils';
import moment from 'moment';

export default {
  name: 'winnerList',
  setup() {
    const route = useRoute();
    const state = reactive({
      collections_id: route.query.id,
      productDetail: {},
      cardShow: false,
      popShow: false
    });
    onMounted(() => {
      getDetail(state.collections_id);
    });

    const timeChange = (time, format = 'YYYY-MM-DD') => {
      return moment(time).format(format);
    };

    const getDetail = async collections_id => {
      const { data } = await getCollectionsInfo({ collections_id });
      state.productDetail = data;
      // state.contents = JSON.parse(state.productDetail.contents);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      timeChange,
      levelsChange
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
.headBox {
  position: relative;
  padding: 0 12px;
  padding-top: 175px;

  .collectionBox {
    background: #2f293f;
    padding: 3px;
    position: relative;

    .model {
      position: absolute;
      z-index: 500;
      top: 0;
      left: 0;
      width: 100%;
      height: 84px;
      background: linear-gradient(180deg, #1c172a 0%, rgba(47, 41, 63, 0) 100%);
    }
  }
  .collectionBg {
    border: 1px solid #867a6c;
    padding-top: 117px;
    padding-bottom: 30px;
    text-align: center;
  }

  .imgIcon {
    position: absolute;
    z-index: 1000;
    width: 180px;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);

    .imgIconBox {
      position: relative;

      .cardImg {
        width: 180px;
        height: 240px;
        object-fit: contain;
      }

      .levelIcon {
        position: absolute;
        left: 50%;
        bottom: 28px;
        transform: translateX(-50%);
        width: 68px;
      }
    }
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
  .headBg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .introduce {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    .pic {
      text-align: center;
      position: relative;
      img {
        width: 237px;
      }

      .questionIcon {
        position: absolute;
        padding: 5px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
    }

    .desc {
      padding: 0 35px;
      text-align: left;
    }
  }

  .iconNum {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    img {
      width: 64px;
    }

    .num {
      margin-left: 10px;
      border: 1px solid #88819b;
      // height: 18px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      overflow: hidden;
      // background: linear-gradient(64deg, #ffffff 0%, #d6f0ff 100%);

      div {
        display: inline-block;
        line-height: 18px;
        // transform: scale(0.9);
        font-size: 9px;
        font-weight: 500;
      }

      :first-child {
        color: #fff;
        background: #1c172a;
        padding: 0 3px;
      }

      :last-child {
        color: #002375;
        padding: 0 3px;
        background: linear-gradient(64deg, #ffffff 0%, #d6f0ff 100%);
      }
    }
  }
}

.paddingBox {
  padding: 0 12px;
  padding-bottom: 108px;
  background: #1c172a;
}

.bgBox {
  padding: 3px;
  background: #2f293f;
  margin-top: 25px;

  .contentBox {
    border: 1px solid #867a6c;
    padding: 8px 17px 12px 17px;

    .countItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 36px;
      border-bottom: 1px solid #494359;

      .name,
      .num {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
.paramsBox {
  .titlePic {
    text-align: center;
    img {
      width: 237px;
    }
  }
}

.cardPop {
  text-align: center;
  .name {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
  img {
    width: 276px;
    margin: 16px 0;
  }
  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }
}

.failPopup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .head {
    padding: 16px 25px 20px 25px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }

  .content {
    padding: 0 25px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
    > div {
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>

<style lang="scss">
#collectDetail {
  .van-popup {
    background: transparent !important;
  }
}
</style>
